<script setup>
const auth = useAuthStore()
const toasts = useToasts()
const config = useRuntimeConfig()

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    default: 'wishlist'
  },
  color: {
    type: String,
    default: 'var(--color)'
  }
})
const loading = ref(false)
const added = computed(() => auth.me?.[props.type].split(',').includes(props.id.toString()))

const active = async (event) => {
  event.preventDefault()
  if (auth.me) {
    try {
      loading.value = true
      const a = auth.me?.[props.type]
      const b = a === '' ? [] : a.split(',')
      let c
      if (added.value) {
        c = b.filter(x => x !== props.id.toString())
      }
      else {
        b.push(props.id.toString())
        c = [...b]
      }
      const d = c.join(',')
      await $fetch(config.public.CMS + '/wp-json/wp/v2/users/' + auth.me.user_id, {
        method: 'post',
        headers: {
          authorization: 'Bearer ' + auth.me.token
        },
        body: {
          acf: {
            [props.type]: d
          }
        }
      })
      if (props.type === 'wishlist') {
        auth.setWishlist(d)
      }
      else {
        auth.setUpit(d)
      }
    }
    catch (error) {
      console.error(error)
    }
    finally {
      loading.value = false
    }
  }
  else {
    toasts.addToast({
      id: +new Date(),
      content: 'Morate biti ulogovani da biste videli listu želja'
    })
  }
}
</script>

<template>
  <div
    class="wish"
    @click.stop="active"
  >
    <vSpinner v-if="loading" />
    <vIcon
      v-else
      :id="added ? 'heart' : 'heart-outline'"
    />
  </div>
</template>

<style scoped>
.wish {
  color: v-bind(color);
}
@media (hover: hover) {
  svg:hover {
    & path {
      fill: rgb(0 0 0);
    }
  }
}
</style>
