<script setup>
// const cart = useCartStore()
// const config = useRuntimeConfig()
// const loading = ref(false)

const props = defineProps({
  cta: {
    type: Boolean,
    default: false
  },
  product: {
    type: Object,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  snap: {
    type: Boolean,
    default: false
  }
})

const formatter = new Intl.NumberFormat('sr-RS', {
  style: 'currency',
  currency: 'RSD',
  minimumFractionDigits: 2
})

// const add = async (id) => {
//   try {
//     loading.value = true
//     cart.useLoading(true)
//     const x = await $fetch(config.public.CMS + '/wp-json/wc/store/cart/add-item', {
//       method: 'post',
//       headers: {
//         'cart-token': cart.cartToken,
//         'content-type': 'application/json'
//       },
//       body: { id, quantity: 1 }
//     })
//     cart.updateCart(x)
//     cart.useMiniCart(true)
//   }
//   catch (error) {
//     console.error(error)
//   }
//   finally {
//     loading.value = false
//     cart.useLoading(false)
//   }
// }

// eslint-disable-next-line no-useless-escape
const to = computed(() => (props.product.permalink || '/')?.replace(/^.*\/\/[^\/]+/, ''))
function limitCharacters(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  else {
    return text
  }
}
const outofstock = computed(() => props.product.stock_status === 'outofstock' || props.product.price === '0.00')
const uom = computed(() => props.product?.meta_data?.filter(x => x.key === '_uom')[0]?.value?.value || '')
</script>

<template>
  <div>
    <NuxtLink
      :to="to"
      :class="['card', { outofstock, snap }]"
    >
      <!-- <span v-if="product.on_sale" class="sale">-{{ (100 + (((product.price || product?.prices?.price) /
        (product.regular_price || product?.prices?.regular_price)) * -
        100)).toFixed()
        }}%</span> -->
      <vIcon
        v-if="outofstock"
        id="cart-off-outline"
        class="sale mode"
        fill="white"
      />
      <vWish
        :id="+product.id"
        class="mode"
        color="white"
      />
      <div class="placeholder">
        <vImage
          v-if="Boolean(product.images?.length)"
          :src="product.images[0]?.src"
        />
        <vImage
          v-if="product.image"
          :src="product.image"
        />
        <!-- <svg v-else height="24" viewBox="0 -960 960 960" width="24" fill="rgb(0 0 0  /.12)">
          <path
            d="m880-195-80-80v-405H638l-73-80H395l-38 42-57-57 60-65h240l74 80h126q33 0 56.5 23.5T880-680v485Zm-720 75q-33 0-56.5-23.5T80-200v-480q0-33 23.5-56.5T160-760h41l80 80H160v480h601l80 80H160Zm466-215q-25 34-62.5 54.5T480-260q-75 0-127.5-52.5T300-440q0-46 20.5-83.5T375-586l58 58q-24 13-38.5 36T380-440q0 42 29 71t71 29q29 0 52-14.5t36-38.5l58 58Zm-18-233q25 24 38.5 57t13.5 71v12q0 6-1 12L456-619q6-1 12-1h12q38 0 71 13.5t57 38.5ZM819-28 27-820l57-57L876-85l-57 57ZM407-440Zm171-57Z" />
        </svg> -->
      </div>
      <div class="fx">
        <!-- <span v-if="product.featured" class="featured">
       <svg height="24" viewBox="0 -960 960 960" width="24"
          <path d=" m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
        </svg>
      </span> -->
        <div
          class="name"
          v-text="limitCharacters(product?.name, 50)"
        />
        <!-- <div>{{ product.permalink }}</div> -->
        <div class="prices">
          <div>
            <!-- <div
              v-if="(product.regular_price !== product.price) || (product?.prices?.regular_price !== product?.prices?.price)"
              v-text="formatter.format(product.regular_price || product?.prices?.regular_price)" class="price_sale" /> -->
            <div
              v-if="(product.price || product?.prices?.price) && product.price !== '0.00'"
              class="price"
              v-text="formatter.format(product.price || product?.prices?.price) + (uom && (' / ' + uom))"
            />
          </div>
          <!-- <div @click="remove(item.key)" class="remove">Ukloni proizvod</div> -->
        </div>

      </div>
    </NuxtLink>
  </div>
</template>

<style scoped>
.mode {
  mix-blend-mode: difference;
}
.fx {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.outofstock {

    & .name,
    & .prices,
    & img {
      opacity: 0.25;
    }
  }

  &.snap {
    width: 75vw;
    scroll-snap-align: center;
  }
}

:deep(.wish) {
  --size: 36px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 3;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.placeholder {
  background-color: rgb(var(--color-rgb) / .06);
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  z-index: 0;
  & img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
    z-index: 1;
    mix-blend-mode: multiply;
  }
}

.name {
  font-size: 12px;
  /* padding-bottom: 4px; */
  font-weight: 400;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.prices {
  padding-top: 8px;
}

.price_sale {
  font-size: 11px;
  text-decoration: line-through;
  color: red;
  font-weight: 600;
}

.price {
  font-size: 12px;
  font-weight: 800;
}

.sale {
  position: absolute;
  top: 4px;
  left: 6px;
  z-index: 3;
}

.featured {
  position: absolute;
  bottom: 1px;
  right: 6px;
}

.add {
  margin-top: 16px;
  display: grid;
  place-items: center;
  height: 48px;
  background-color: black;
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  padding: 0 16px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: rgba(0 0 0 / .25) 0px 13px 27px -5px, rgba(0 0 0 / .5) 0px 8px 16px -8px;
  user-select: none;

  &:active {
    transform: scale(.95);
    box-shadow: none;
  }

  &.disabled {
    background-color: rgb(0 0 0 / .03);
    color: rgb(0 0 0 / .24);
    font-weight: 600;
    box-shadow: none;
    pointer-events: none;
  }

  &:disabled {
    box-shadow: none;
  }
}
</style>
